export class Project {
  public id: number;
  public name: string;
  public description: string;
  public builderName: string;
  public address: Address = new Address();
  public location: string;
  public map: string;
  public tagLine: string;
  public contact: Contact = new Contact();
  public webSite: string;
  public status: boolean;
  public gallery: Gallery = new Gallery();
  public sitePlan: SitePlan = new SitePlan();
  public units: Unit;
  public modelActive: number;
  public newId: string;
  public models: Array<Model> = [];
  public showInfoProject: boolean;
}

export class Address {
  public street: string;
  public city: string;
  public state: string;
  public zipCode: string;
}

export class Contact {
  public name: string;
  public title: string;
  public phone: string;
}

export class Unit {
  public quantity: number;
  public status: [];
}

export class Model {
  public id: string;
  public name: string;
  public description: number;
  public coverPicture: string;
  public modelPrice: string;
  public views: Array<View> = [];
}

export class View {
  public id: string;
  public name: string;
  public subView: Array<SubView> = [];
}

export class SubView {
  public id: string;
  public name: string;
  public options: Array<Option> = [];
}

export class Option {
  public id: string;
  public name: string;
  public description: string;
  public pathBasePicture: string;
  public subOptions: Array<SubOption> = [];
}

export class SubOption {
  public id: string;
  public name: string;
  public layers: Array<Layer> = [];
}


export class Layer {
  public level: number;
  public itemActive: number;
  public itemDefaultName: string;
  public itemDefaultCost: number;
  public itemDefaultThumbnail: string;
  public items: Array<Item> = [];
}

export class Item {
  public name: string;
  public cost: number;
  public thumbnail: string;
  public pathLayerPicture: string;
}

export class ImageStorage {
  public files: Array<string> = [];
  public base_url: string = '';
}

export class Gallery {
  public coverImg: String;
  public images = [];
}

export class SitePlan {
  public mapSvg: string;
  public lotSelected: number;
  public modeClustersLots: boolean;
  public clusterSelected: number;
  public lots = [];
  public imagePlanPath: string;
  public clusterImagenPlanPath: string;
  public clusterMapSvg: string;
  public clusterMapLotsSvg: string;
  public clusters: Array<Cluster> = [];
  public clusterLots = [];
}

export class Cluster {
  public id: string;
  public clusterName: string;
  public available: boolean;
  public type: string;
  public lotSeletec: number;
  public mapSvg: string;
  public imagePlanPath: string;
  public lots = [];
}