export const environment = {
  production: false,
  apiProjectUrl: 'https://a314-projects-dev.evepark.avwaveinteractive.com/projects',
  apiUrlAdmin: 'https://a314-admin-users-dev.evepark.avwaveinteractive.com/admin-users',
  apiGoogleStorage: 'https://a314-google-storage-dev.evepark.avwaveinteractive.com/storage',
  urlStorage: 'https://evepark-avwave-dev.storage.googleapis.com/',
  testUser: {
    // tslint:disable-line
    token: 'cualquiercosa',
    email: 'user@user.user',
  },
};
